import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaWhatsapp } from "react-icons/fa";

const Refer = () => {
  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(`https://apifrontseed.khelohub.com/me`, { headers })
      .then((res) => {
        setUser(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);
  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(`https://apifrontseed.khelohub.com/referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
        console.log(res.data);
      });
  };

  useEffect(() => {
    Cashheader();
  }, []);

  const copyCode = (e) => {
    const referralLink = `https://khelohub.com/login/${user.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <div>
      <div className="leftContainer" style={{ height: "100vh" }}>
        <div className="">
           <div className="allpage_padding">
            

        <div className={`${css.center_xy} pt-5`}>
        <div className="card_wrap">
         {/* <div class="card_title referralcode">Referral Code</div> */}
          <picture className="mt-1">
            <img
              alt="img"
             
              src={process.env.PUBLIC_URL + "Images/refer/refer1.png"}
              className="refer_img"
            />
          </picture>
          </div>
          <div className="mb-1 text-dark w-100">
            <div className="font-15 d-flex justify-content-center text-dark">
              Earn now unlimited
              <span aria-label="party-face">🥳</span>
            </div>
            <div className="d-flex justify-content-center font-weight-bold ">
              Refer your friends now!
            </div>
            {/* <div className="mt-3 text-center font-9">
              Current Earning:
              <b>
                {user.referral_wallet}
              </b>
              <Link className="ml-2" to="/Redeem">
                Redeem
              </Link>
            </div> */}
            <div className="text-center font-9">
              Total Earned:
              <b>{user.referral_earning}</b>
            </div>
            <div className={`${css.progress}`}>
              <div
                className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                aria-valuenow={user.referral_earning}
                aria-valuemax={10000}
                style={{ width: `${(user.referral_earning * 200) / 10000}%` }}
              >
              </div>
            </div>
            <div className="font-9">
              <span>Max: 10,000</span>
              <Link className="float-right text-success" to="/update-pan">
                Upgrade Limit
              </Link>
            </div>
            <div>
            Your Refer Code:
            </div>
            <div className={`${css.text_bold} mt-3 text-center text-dark`}>
            <h6 class="refer_code">{user.referral_code}</h6>  
            <button class="refer_code_btn custom_blue_btn"  onClick={(e) => copyCode(e)}>Copy Link</button>
              {/* <button
                className="ri-clipboard-fill ml-2 rounded btn-info text-dark"
                style={{ fontSize: "20px", color: "#007bff" }}
                onClick={(e) => copyCode(e)}
              >
                Copy Link
              </button> */}
            </div>

            <div className="d-flex justify-content-center">
              Total Refers:&nbsp;
              <b>{cardData && cardData}</b>
            </div>
          </div>
        </div>
        <div class="card_wrap  mt-3">
                      <div class="card_title">   Refer &amp; Earn Rules</div>
                      <div class="card_content refer_how">
                      <div className="d-flex align-items-center m-3">
            <picture>
              <img
                alt="img"
                width="82px"
                src={process.env.PUBLIC_URL + "Images/refer/giftbanner.png"}
                className="snip-img"
              />
            </picture>
            <div
              className={`${css.font_9} mx-3 text-dark`}
             
            >
              <div>
                When your friend signs up on Khelohub Ludo from your referral link,
              </div>
              <div className={`${css.font_8} ${css.c_green} mt-2`}>
                You get
                <strong>&nbsp; 2% Commission</strong>
                &nbsp;on your
                <strong>&nbsp; referral's winnings.</strong>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center m-3">
            <picture>
              <img
                alt="img"
                width="82px"
                src={process.env.PUBLIC_URL + "Images/refer/banner.png"}
                className="snip-img"
              />
            </picture>
            <div
              className={`${css.font_9} mx-3 text-dark`}
              
            >
              <div>Suppose your referral plays a battle for a 10000 Cash,</div>
              <div className={`${css.font_8} ${css.c_green} mt-2`}>
                You get
                <strong>a 200 Cash</strong>
                <strong></strong>
              </div>
            </div>
          </div>
                      </div>
                  </div>
      

        <div className={`${css.refer_footer} pt-2 bg-1`}>
          <a
            href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.Commission Charge - 5% OnlyReferral - 2% On All Games.24x7 whats app Chat Support Instant Withdrawal Via UPI/Bank Register Now, My refer code is    👇  ${user.referral_code}.

            https://khelohub.com/login/${user.referral_code}`}
            className="custom_green_btn d-flex gap-2 justify-content-center align-items-center"
          >
         
             <FaWhatsapp/> Share in Whatsapp
          </a>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Refer;
