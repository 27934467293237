import React from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

const All_policy = () => {
  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="mt-5 py-4 px-3">
          <div className="card_title">
            All Policy
          </div>
          <ul
            style={{ fontSize: "16px" }}
            className=" list-unstyled ml-2 mt-2 "
          >
            <div className="unstyled">
              <Link to="/term-condition">
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  Terms & Conditions
                </li>
              </Link>
              <Link to="/PrivacyPolicy">
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  Privacy policy
                </li>
              </Link>

              <Link to="/refund-policy">
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  Refund/Cancellatioin Policy
                </li>
              </Link>

              <Link to="/contact-us">
                {" "}
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  Contact Us
                </li>{" "}
              </Link>

              <Link to="/responsible-gaming">
                {" "}
                <li
                  className="col giveHover"
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                >
                  Responsible Gaming{" "}
                </li>
              </Link>
              <Link to="About">
                {" "}
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  About Us
                </li>{" "}
              </Link>

              <Link to="#">
                {" "}
                <li
                  style={{ color: "#000", fontFamily: "sans-serif" }}
                  className="col giveHover"
                >
                  AKSHAT WEBTECH PRIVATE LIMITED
                </li>{" "}
              </Link>

            </div>
          </ul>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default All_policy;
