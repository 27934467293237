import React from "react";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

const RefundPolicy = () => {
  const appName = "Khelohub Ludo";

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="allpage_padding policies">
        <div class="card_title mt-2">Refund Policy</div>
          <div className="">
      <p className="p2">
              Thanks for being a patron with {appName} Private Limited.
              (referred as “{appName}”) . If you are not entirely satisfied with
              your subscription, we are here to help.
            </p>
            <h5 className="collapsible-header">Refund</h5>
            <p className="p2">
              Once we receive your Refund request, we will inspect it and notify
              you on the status of your refund.
            </p>
            <p className="p2">
              If your refund request is approved, we will initiate a refund to
              your credit card (or original method of payment) within 7 working
              days. You will receive the credit within a certain amount of days,
              depending on your card issuer's policies.
            </p>
            <p className="p2">
              In case of unforeseen technical glitch, {appName} Private
              Limited.would refund subscription upon reviewing the complaint.
              Final decision lies with the company.
            </p>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default RefundPolicy;
